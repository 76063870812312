/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ServiceBusClient } from '@azure/service-bus'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import ReactHlsPlayer from 'react-hls-player'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded'
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  Dialog as MuiDialog,
  DialogContent,
} from '@material-ui/core'

import CustomImageAnnotator, {
  useImageAnnotator,
} from '../../components/custom-image-annotator/custom-image-annotator.component'
import ImageWithLabels from '../../components/image-with-labels/image-with-labels.component'
import LinearProgressWithLabel from '../../components/linear-progress-with-label/linear-progress-with-label'
import imageCaptureStyles from './image_capture.styles'
import RequestService from '../../services/request/request-service'
import { AB_SUBSCRIPTION_QUERY, UPLOAD_PACKMAN_QUERY } from '../../shared/constants/queries'

const categories = ['Good', 'Double', 'Spur', 'Major']

const categoryMappings = {
  index: {
    0: 'Good',
    1: 'Double',
    2: 'Spur',
    3: 'Major',
  },
  name: {
    good: 0,
    double: 1,
    spur: 2,
    major: 3,
  },
}

// Categories Dialog Component
const LabelDialog = ({ items, itemsChanged, onEdit, onDelete, onClose, offset }) => {
  const classes = imageCaptureStyles()

  const handleCheck = (event) => {
    let selected = items
    if (event.target.checked) selected = [...selected, event.target.value]
    else selected = selected.filter((item) => item !== event.target.value)
    selected.sort((c1, c2) => categories.indexOf(c1) - categories.indexOf(c2))
    itemsChanged(selected)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClose()
    }
  }

  const handleInnerKeyDown = (e) => {
    if (e.key === 'Escape') {
      e.stopPropagation()
    }
  }

  return (
    <div
      className={classes.dialog_bg}
      onClick={onClose}
      onKeyDown={handleKeyDown}
      role="dialog"
      aria-modal="true"
      tabIndex={0}
    >
      <div
        className={classes.dialog}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={handleInnerKeyDown}
        style={{ left: offset.X, top: offset.Y }}
        role="document"
      >
        <button type="button" onClick={onEdit}>
          Edit
        </button>
        <button type="button" onClick={onDelete}>
          Delete
        </button>
        {categories.map((category, index) => (
          <div key={index}>
            <input
              id={`chb${index}`}
              value={category}
              type="checkbox"
              onChange={handleCheck}
              checked={items.includes(category)}
            />
            <label htmlFor={`chb${index}`}>{category}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

const ImageLabeler = () => {
  const history = useHistory()
  const { setHandles, annotator } = useImageAnnotator()
  const [shapes, setShapes] = useState(null)
  const [dialog, setDialog] = useState({ show: false, shape: undefined })
  const [img, setImg] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isCapturing, setIsCapturing] = useState(false)
  const [capturedImages, setCapturedImages] = useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)
  const [modelDetectionResults, setModelDetectionResults] = useState([])
  const [annotatorKey, setAnnotatorKey] = useState(0)
  const { t } = useTranslation()
  const [status, setStatus] = useState('')
  const [progress, setProgress] = useState({})
  const [showProgress, setShowProgress] = useState(false)
  const classes = imageCaptureStyles()
  const videoRef = useRef(null)
  const [serviceBusClient, setServiceBusClient] = useState(null)
  const [sender, setSender] = useState(null)
  const [isServiceBusReady, setIsServiceBusReady] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [autoTrainEnabled, setAutoTrainEnabled] = useState(false)
  const [queueName] = useState(process.env.REACT_APP_SERVICE_BUS_DA_SERVER_QUEUE_NAME)
  const [topicName, setTopicName] = useState('')
  const [subscriptionName, setSubscriptionName] = useState('')
  const [isVideoReady, setIsVideoReady] = useState(false)
  const [storedShapes, setStoredShapes] = useState(null)
  const [capturedImageShapes, setCapturedImageShapes] = useState({})
  const [storageImageShapes, setStorageImageShapes] = useState({})
  const [storageImages, setStorageImages] = useState([])
  const [page, setPage] = useState(1)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [capturedImagesDialogOpen, setCapturedImagesDialogOpen] = useState(false)
  const [storageImagesDialogOpen, setStorageImagesDialogOpen] = useState(false)
  const [currentImageSource, setCurrentImageSource] = useState(null)
  const [continuationToken, setContinuationToken] = useState(null)
  const PAGE_SIZE = 10
  const LIVE_CAMERA_URL = process.env.REACT_APP_LIVE_CAMERA_URL

  const handleCapturedImagesExpand = () => {
    setCapturedImagesDialogOpen(true)
  }

  const handleStorageImagesExpand = () => {
    setStorageImagesDialogOpen(true)
  }

  useEffect(() => {
    resetStorageImages()
  }, [])

  useEffect(() => {
    if (topicName && subscriptionName) {
      setupServiceBus()
    }
  }, [topicName, subscriptionName])

  useEffect(() => {
    return async () => {
      if (sender) {
        await sender.close()
      }
      if (serviceBusClient) {
        await serviceBusClient.close()
      }
    }
  }, [])

  useEffect(() => {
    const createQueueAndTopicAndSubscription = async () => {
      try {
        const url = `${AB_SUBSCRIPTION_QUERY}?topic_name=5000`
        const response = await RequestService.Post(url, history, null, { isBlankInstance: true })
        console.log('response.data:', response.data)
        setTopicName(response.data.topic_name)
        setSubscriptionName(response.data.subscription_name)
      } catch (error) {
        console.error(error)
      }
    }

    createQueueAndTopicAndSubscription()
  }, [history])

  useEffect(() => {
    if (isServiceBusReady) {
      checkAutoTrainStatus()
    }
  }, [isServiceBusReady])

  useEffect(() => {
    let intervalId
    if (isCapturing) {
      intervalId = setInterval(() => {
        if (videoRef.current) {
          const video = videoRef.current
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          const ctx = canvas.getContext('2d')
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
          const dataURL = canvas.toDataURL()
          setCapturedImages((prevImages) => [...prevImages, dataURL])
        }
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isCapturing])

  useEffect(() => {
    if (progress !== null && progress >= 0 && progress < 100) {
      setShowProgress(true)
    } else if (progress === 100) {
      setShowProgress(true)
      setTimeout(() => {
        setShowProgress(false)
        setAutoTrainEnabled(true)
      }, 15000)
    } else {
      setShowProgress(false)
    }
  }, [progress])

  const loadStorageImages = async () => {
    try {
      setIsLoadingMore(true)

      // Build URL với continuation token
      const url = continuationToken
        ? `${UPLOAD_PACKMAN_QUERY}getimageslabels?continuationToken=${continuationToken}&pageSize=${PAGE_SIZE}`
        : `${UPLOAD_PACKMAN_QUERY}getimageslabels?pageSize=${PAGE_SIZE}`

      const response = await RequestService.Get(url, history)

      // Lấy data từ response
      const { results, continuationToken: newToken, hasMore } = response.data

      // Update state
      setStorageImages((prev) => [...prev, ...results])
      setContinuationToken(newToken)
      setHasMore(hasMore)
    } catch (error) {
      console.error('Error loading storage images:', error)
      enqueueSnackbar(t('error_loading_storage'), { variant: 'error' })
    } finally {
      setIsLoadingMore(false)
    }
  }

  const resetStorageImages = () => {
    setStorageImages([])
    setContinuationToken(null)
    setHasMore(true)
    loadStorageImages()
  }

  const handleLoadMore = () => {
    if (!isLoadingMore && hasMore) {
      console.log('load more')
      loadStorageImages(page + 1)
    }
  }

  const handleVideoReady = () => {
    setIsVideoReady(true)
  }

  const handleStorageImageClick = (image, index) => {
    if (dialog.show) {
      hideDialog()
    }

    if (annotator && selectedImageIndex !== null && currentImageSource === 'storage') {
      const currentShapes = annotator.getShapes()
      if (currentShapes.length > 0) {
        setStorageImageShapes((prev) => ({
          ...prev,
          [selectedImageIndex]: {
            shapes: currentShapes,
          },
        }))
      }
    }

    // Ưu tiên sử dụng shapes đã được lưu
    const savedShapes = storageImageShapes[index]?.shapes
    const labelsWithCategories =
      savedShapes ||
      image.labels?.map((label) => ({
        ...label,
        categories: [categoryMappings.index[label.categoryIndex] || 'Unknown'],
      }))

    setImg(image.url)
    setSelectedImageIndex(index)
    setAnnotatorKey((prev) => prev + 1)
    setShapes(labelsWithCategories || [])
    setStoredShapes(labelsWithCategories || [])
    setCurrentImageSource('storage')
  }

  const checkAutoTrainStatus = async () => {
    try {
      await sendServiceBusMessage('trainingprogress')
    } catch (error) {
      console.error('Error fetching auto train status:', error)
      setAutoTrainEnabled(false)
    }
  }

  const mapProgress = {
    Starting: 0,
    Preparing: 10,
    Queued: 20,
    Running: 40,
    Finalizing: 90,
    Completed: 100,
    Failed: 100,
    Canceled: 100,
  }

  const setupServiceBus = async () => {
    try {
      const client = new ServiceBusClient(process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING)
      const sender = client.createSender(queueName)
      const receiver = client.createReceiver(topicName, subscriptionName)

      setServiceBusClient(client)
      setSender(sender)

      receiver.subscribe({
        processMessage: async (message) => {
          try {
            if (message.subject === 'post_model') {
              handleModelDetectionResults(message.body.detection)
              await receiver.completeMessage(message)
            } else if (message.subject === 'run_training') {
              if (message.body?.key_status && message.body?.progress) {
                const { key_status, progress } = message.body
                enqueueSnackbar(`Received message: ${key_status} ${progress}`, { variant: 'info' })

                const statusValue = key_status
                const progressValue = mapProgress[statusValue] || 0
                setStatus(statusValue)
                setProgress(progressValue)
              } else if (message.body?.message) {
                enqueueSnackbar(`Received message: ${message.body.message}`, { variant: 'success' })
              } else if (message.body?.error_message) {
                enqueueSnackbar(`Received message: ${message.body.error_message}`, { variant: 'error' })
                setAutoTrainEnabled(true)
              }
              await receiver.completeMessage(message)
            } else if (message.subject === 'trainingprogress') {
              if (message.body.messages === 'No job run') {
                setAutoTrainEnabled(true)
              } else {
                setAutoTrainEnabled(false)
                const statusValue = message.body.progress
                const progressValue = mapProgress[statusValue] || 0

                setStatus(statusValue)
                setProgress(progressValue)
              }
              await receiver.completeMessage(message)
            }
          } catch (error) {
            console.error('Error processing message:', error)
          }
        },
        processError: async (err) => {
          console.error('Error receiving message:', err)
        },
      })
      setIsServiceBusReady(true)
    } catch (error) {
      console.error('Error setting up Service Bus:', error)
      setIsServiceBusReady(false)
    }
  }

  const sendServiceBusMessage = async (subject, body) => {
    if (!sender) {
      console.error('Sender not initialized')
      return
    }

    try {
      const message = {
        body,
        subject,
      }
      await sender.sendMessages(message)
    } catch (error) {
      console.error(`Error sending message: ${subject}`, error)
    }
  }

  const selectedCategoriesChanged = useCallback(
    (items) => {
      if (dialog.shape) {
        dialog.shape.categories = items
        setDialog({ ...dialog })
      }
    },
    [dialog.shape]
  )

  const hideDialog = () => {
    setDialog({
      show: false,
      shape: null,
    })
  }

  const hideAndUpdateCategories = () => {
    try {
      if (dialog.show && dialog.shape && annotator) {
        annotator.updateCategories(dialog.shape.id, dialog.shape.categories || [])

        const updatedShapes = annotator.getShapes()
        if (selectedImageIndex !== null && updatedShapes) {
          if (currentImageSource === 'captured') {
            setCapturedImageShapes((prev) => ({
              ...prev,
              [selectedImageIndex]: {
                shapes: updatedShapes,
              },
            }))
          } else if (currentImageSource === 'storage') {
            setStorageImageShapes((prev) => ({
              ...prev,
              [selectedImageIndex]: {
                shapes: updatedShapes,
              },
            }))
          }
          setShapes(updatedShapes)
          setStoredShapes(updatedShapes)
        }
      }
    } catch (error) {
      console.error('Error in hideAndUpdateCategories:', error)
    } finally {
      hideDialog()
    }
  }

  const toggleCapture = () => {
    if (isCapturing || isVideoReady) {
      setIsCapturing((prev) => !prev)
    }
  }

  const handleImageClick = (index) => {
    if (dialog.show) {
      hideDialog()
    }

    if (annotator && selectedImageIndex !== null && currentImageSource === 'captured') {
      const currentShapes = annotator.getShapes()
      if (currentShapes.length > 0) {
        setCapturedImageShapes((prev) => ({
          ...prev,
          [selectedImageIndex]: {
            shapes: currentShapes,
          },
        }))
      }
    }

    setImg(capturedImages[index])
    setSelectedImageIndex(index)
    setAnnotatorKey((prev) => prev + 1)
    setCurrentImageSource('captured')

    const savedData = capturedImageShapes[index]
    if (savedData?.shapes) {
      setShapes(savedData.shapes)
      setStoredShapes(savedData.shapes)
    } else {
      setShapes([])
      setStoredShapes([])
    }
  }

  const handleSave = async () => {
    if (!annotator) return

    const currentShapes = annotator.getShapes()

    if (!currentShapes || currentShapes.length === 0) {
      enqueueSnackbar(t('please_draw_labels'), { variant: 'warning' })
      return
    }

    const shapesWithoutCategories = currentShapes.some((shape) => !shape.categories || shape.categories.length === 0)

    if (shapesWithoutCategories) {
      enqueueSnackbar(t('please_select_category_for_all_labels'), { variant: 'warning' })
      return
    }

    setIsSaving(true)

    try {
      // Generate label data cho backend
      const getLabelData = (shapes) => {
        return shapes
          .map((shape) => {
            if (shape.type === 'rectangle' && shape.categories && shape.categories.length > 0) {
              const [x_min, y_min] = shape.points[0]
              const [x_max, y_max] = shape.points[2]
              const categoryName = shape.categories[0].toLowerCase()
              const categoryIndex = categoryMappings.name[categoryName]

              if (categoryIndex === undefined) {
                console.error(`Invalid category: ${categoryName}`)
                return null
              }

              return `${categoryIndex} ${x_min} ${y_min} ${x_min} ${y_max} ${x_max} ${y_max} ${x_max} ${y_min}`
            }
            return null
          })
          .filter((shape) => shape !== null)
          .join('\n')
      }

      if (currentImageSource === 'storage') {
        // Logic for storage images
        const filename = img.split('/').pop()
        const labelData = getLabelData(currentShapes)
        const labelBlob = new Blob([labelData], { type: 'text/plain' })
        const formData = new FormData()
        formData.append('labelFile', labelBlob, filename.replace('.png', '.txt'))

        await RequestService.Post(`${UPLOAD_PACKMAN_QUERY}uploadimageslabels`, history, formData)

        // Format lại shapes để hiển thị trên UI giống như captured images
        const updatedLabels = currentShapes.map((shape) => ({
          type: 'rectangle',
          points: shape.points,
          categories: shape.categories,
        }))

        setStorageImages((prevImages) =>
          prevImages.map((image) => {
            if (image.url === img) {
              return {
                ...image,
                labels: updatedLabels,
              }
            }
            return image
          })
        )

        enqueueSnackbar(t('save_success'), { variant: 'success' })
        setIsSaving(false)
      } else {
        // Logic for captured images
        const imgElement = document.createElement('img')
        imgElement.crossOrigin = 'anonymous'
        imgElement.src = img

        imgElement.onload = async () => {
          const canvas = document.createElement('canvas')
          canvas.width = imgElement.width
          canvas.height = imgElement.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(imgElement, 0, 0)

          canvas.toBlob(async (imageBlob) => {
            const labelData = getLabelData(currentShapes)
            const labelBlob = new Blob([labelData], { type: 'text/plain' })
            const formData = new FormData()
            const timestamp = Date.now()
            formData.append('imageFile', imageBlob, `original_image_${timestamp}.png`)
            formData.append('labelFile', labelBlob, `original_image_${timestamp}.txt`)

            try {
              await RequestService.Post(`${UPLOAD_PACKMAN_QUERY}uploadimageslabels`, history, formData)
              enqueueSnackbar(t('save_success'), { variant: 'success' })
              loadStorageImages()
            } catch (error) {
              console.error('Error saving labeled image:', error)
              enqueueSnackbar(t('save_error'), { variant: 'error' })
            } finally {
              setIsSaving(false)
            }
          })
        }

        imgElement.onerror = () => {
          console.error('Failed to load image.')
          enqueueSnackbar(t('image_load_error'), { variant: 'error' })
          setIsSaving(false)
        }
      }
    } catch (error) {
      console.error('Error in save process:', error)
      enqueueSnackbar(t('save_error'), { variant: 'error' })
      setIsSaving(false)
    }
  }

  const handleTrainModel = async () => {
    try {
      setAutoTrainEnabled(false)
      await sendServiceBusMessage('run_training')
    } catch (error) {
      setAutoTrainEnabled(true)
      enqueueSnackbar('Error starting training. Please try again.', { variant: 'error' })
    }
  }

  const handleTestModel = async () => {
    if (!img) {
      enqueueSnackbar(t('select_image_warning'), { variant: 'warning' })
      return
    }

    const canvas = document.createElement('canvas')
    const imgElement = document.createElement('img')
    imgElement.src = img

    imgElement.onload = async () => {
      canvas.width = imgElement.width
      canvas.height = imgElement.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(imgElement, 0, 0)

      canvas.toBlob(async (blob) => {
        if (!blob) {
          console.error('Failed to create a blob from the canvas.')
          return
        }

        const formData = new FormData()
        formData.append('imageFile', blob, `test_image_${Date.now()}.png`)

        try {
          const uploadResponse = await RequestService.Post(
            `${UPLOAD_PACKMAN_QUERY}uploadimageslabels`,
            history,
            formData
          )
          const imageUrl = uploadResponse.data[0]
          await sendServiceBusMessage('post_model', { img_url: imageUrl })
        } catch (error) {
          console.error('Error testing model:', error)
          enqueueSnackbar(t('testing_error'), { variant: 'error' })
        }
      }, 'image/png')
    }

    imgElement.onerror = () => {
      console.error('Failed to load image.')
      enqueueSnackbar(t('image_load_error'), { variant: 'error' })
    }
  }

  const handleModelDetectionResults = (detectionResults) => {
    if (detectionResults && detectionResults.length > 0) {
      const newShapes = detectionResults.map((detection, index) => {
        const [x_min, y_min] = detection.bbox[0]
        const [x_max, y_max] = detection.bbox[2]

        const category = categoryMappings.index[detection.class_name] || detection.class_name

        return {
          id: index,
          type: 'rectangle',
          points: [
            [x_min, y_min],
            [x_min, y_max],
            [x_max, y_max],
            [x_max, y_min],
          ],
          categories: [category],
          conf_score: detection.conf_score,
        }
      })

      setShapes(newShapes)
      setAnnotatorKey((prevKey) => prevKey + 1)
      setModelDetectionResults([{ detection: detectionResults }])
    } else {
      console.error('No detection results found.')
      enqueueSnackbar(t('no_detection_results'), { variant: 'warning' })
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <div className={classes.videoStreamBody}>
          <ReactHlsPlayer
            playerRef={videoRef}
            src={LIVE_CAMERA_URL}
            muted
            autoPlay
            width="100%"
            height="100%"
            onLoadedData={handleVideoReady}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleCapture}
          disabled={!isVideoReady}
          startIcon={
            !isVideoReady ? (
              <CircularProgress size={20} color="inherit" />
            ) : isCapturing ? (
              <StopIcon />
            ) : (
              <PlayArrowIcon />
            )
          }
        >
          {isVideoReady ? (isCapturing ? t('stop_capture') : t('start_capture')) : t('waiting_for_video')}
        </Button>
        <Button onClick={() => annotator?.drawRectangle()} title="Draw Rectangle" disabled={!img || !annotator}>
          {t('start_draw')}
        </Button>
        <Button
          onClick={() => {
            if (annotator) {
              const updatedShapes = annotator.getShapes()
              setShapes(updatedShapes)
              setStoredShapes(updatedShapes)
              if (selectedImageIndex !== null) {
                if (currentImageSource === 'captured') {
                  setCapturedImageShapes((prev) => ({
                    ...prev,
                    [selectedImageIndex]: {
                      shapes: updatedShapes,
                    },
                  }))
                } else if (currentImageSource === 'storage') {
                  setStorageImageShapes((prev) => ({
                    ...prev,
                    [selectedImageIndex]: {
                      shapes: updatedShapes,
                    },
                  }))
                }
              }
              annotator.stopEdit()
            }
          }}
          disabled={!img || !annotator}
        >
          {t('edit_done')}
        </Button>
        <Button onClick={() => annotator?.zoom(1.2)} disabled={!img || !annotator}>
          {t('zoom_in')}
        </Button>
        <Button onClick={() => annotator?.zoom(0.8)} disabled={!img || !annotator}>
          {t('zoom_out')}
        </Button>
        <Button onClick={() => annotator && setShapes(annotator.getShapes())} disabled={!img || !annotator}>
          {t('get_shapes')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleTrainModel}
          style={{ margin: '20px 0' }}
          disabled={!autoTrainEnabled}
        >
          {t('train_model')}
        </Button>
        {showProgress && (
          <Box>
            <h3>{t('training_progress')}</h3>
            <LinearProgressWithLabel value={progress} status={status} />
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleTestModel}
          style={{ margin: '20px 0' }}
          disabled={!img}
        >
          {t('test_model')}
        </Button>
      </div>

      <div className={classes.imageArea}>
        {/* Captured Images Section */}
        <Box className={classes.sectionHeader}>
          <Typography variant="h6">Captured Images</Typography>
          <IconButton onClick={handleCapturedImagesExpand}>
            <FullscreenRoundedIcon />
          </IconButton>
        </Box>
        <div className={classes.imageScrollArea}>
          {capturedImages.length > 0 ? (
            capturedImages.map((image, index) => (
              <Box key={index} className={classes.imageWrapper}>
                <ImageWithLabels
                  image={image}
                  shapes={capturedImageShapes[index]?.shapes}
                  containerClass={classes.thumbnailContainer}
                  imageClass={classes.capturedImage}
                  onClick={() => handleImageClick(index)}
                  isSelected={index === selectedImageIndex && currentImageSource === 'captured'}
                />
                <Typography variant="caption">
                  Image {index + 1}
                  {capturedImageShapes[index]?.shapes?.length > 0 &&
                    ` (${capturedImageShapes[index].shapes.length} labels)`}
                </Typography>
              </Box>
            ))
          ) : (
            <Box className={classes.noImages}>
              <Typography variant="body1">{t('no_captured_images')}</Typography>
              <Typography variant="caption">{t('click_start_capture')}</Typography>
            </Box>
          )}
        </div>

        {/* Storage Images Section */}
        <Box className={classes.sectionHeader}>
          <Typography variant="h6">Storage Images</Typography>
          <IconButton onClick={handleStorageImagesExpand}>
            <FullscreenRoundedIcon />
          </IconButton>
        </Box>
        <div className={classes.storageArea}>
          {/* Storage Images Section */}
          <div className={classes.storageScrollArea}>
            {storageImages.length > 0 ? (
              storageImages.map((image, index) => {
                // Ưu tiên sử dụng shapes đã được chỉnh sửa từ storageImageShapes
                const savedShapes = storageImageShapes[index]?.shapes
                const labelsWithCategories =
                  savedShapes ||
                  image.labels?.map((label) => ({
                    ...label,
                    categories: [categoryMappings.index[label.categoryIndex] || 'Unknown'],
                  }))

                return (
                  <Box key={index} className={classes.imageWrapper}>
                    <ImageWithLabels
                      image={image.url}
                      shapes={labelsWithCategories}
                      containerClass={classes.thumbnailContainer}
                      imageClass={classes.capturedImage}
                      onClick={() => handleStorageImageClick(image, index)}
                      isSelected={img === image.url && currentImageSource === 'storage'}
                    />
                    <Typography variant="caption">
                      Image {index + 1}
                      {labelsWithCategories?.length > 0 && ` (${labelsWithCategories.length} labels)`}
                    </Typography>
                  </Box>
                )
              })
            ) : (
              <Box className={classes.noImages}>
                <Typography variant="body1">{t('no_storage_images')}</Typography>
              </Box>
            )}
          </div>
          {hasMore && (
            <div className={classes.loadMoreContainer}>
              <Button
                className={classes.loadMoreButton}
                onClick={handleLoadMore}
                disabled={isLoadingMore}
                variant="text"
              >
                <div className={classes.loadMoreText}>
                  {isLoadingMore ? (
                    <>
                      <CircularProgress size={20} className={classes.loadingSpinner} />
                      Loading...
                    </>
                  ) : (
                    'Load More'
                  )}
                </div>
              </Button>
            </div>
          )}
        </div>

        {/* Annotator Container */}
        <div className={classes.annotatorContainer}>
          {img && (
            <CustomImageAnnotator
              key={`${annotatorKey}-${selectedImageIndex}`}
              setHandles={setHandles}
              shapes={storedShapes || shapes}
              imageUrl={img}
              naturalSize={false}
              crossOrigin="anonymous"
              onAdded={(shape) => {
                if (shape) {
                  const newShape = { ...shape, categories: [] }
                  setDialog({
                    show: true,
                    shape: newShape,
                  })
                }
              }}
              onContextMenu={(shape) => {
                if (shape) {
                  setDialog({
                    show: true,
                    shape,
                  })
                }
              }}
              onReady={(annotator) => {
                if (storedShapes?.length > 0) {
                  requestAnimationFrame(() => {
                    setShapes(storedShapes)
                  })
                }
              }}
              onEditDone={(annotator) => {
                const updatedShapes = annotator.getShapes()
                setShapes(updatedShapes)
                setStoredShapes(updatedShapes)
                if (selectedImageIndex !== null) {
                  if (currentImageSource === 'captured') {
                    setCapturedImageShapes((prev) => ({
                      ...prev,
                      [selectedImageIndex]: {
                        shapes: updatedShapes,
                      },
                    }))
                  } else if (currentImageSource === 'storage') {
                    setStorageImageShapes((prev) => ({
                      ...prev,
                      [selectedImageIndex]: {
                        shapes: updatedShapes,
                      },
                    }))
                  }
                }
              }}
            />
          )}
        </div>

        {/* Action Buttons */}
        <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
          <Button variant="contained" color="secondary" onClick={handleSave} disabled={isSaving || !annotator}>
            {isSaving ? `${t('saving')}...` : t('save_labeled_image')}
          </Button>
        </Grid>

        {/* Shapes Info */}
        {(shapes || storedShapes) && (
          <div className={classes.shapesInfo}>{JSON.stringify(shapes || storedShapes, null, 2)}</div>
        )}

        {/* Detection Results */}
        <div className={classes.detectionResults}>
          <h3>{t('model_detection_results')}</h3>
          {modelDetectionResults.map((result, index) => (
            <div key={index}>
              <ul>
                {result.detection.map((detection, detIndex) => (
                  <li key={detIndex}>
                    Class: {detection.class_name}, Confidence: {detection.conf_score}, BBox:{' '}
                    {JSON.stringify(detection.bbox)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* Fullscreen Dialogs */}
      <MuiDialog
        open={capturedImagesDialogOpen}
        onClose={() => setCapturedImagesDialogOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={2}>
            {capturedImages.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Box className={classes.dialogImageWrapper}>
                  <ImageWithLabels
                    image={image}
                    shapes={capturedImageShapes[index]?.shapes}
                    containerClass={classes.dialogImageContainer}
                    imageClass={classes.dialogImage}
                    onClick={() => {
                      handleImageClick(index)
                      setCapturedImagesDialogOpen(false)
                    }}
                  />
                  <Typography variant="caption">
                    Image {index + 1}
                    {capturedImageShapes[index]?.shapes?.length > 0 &&
                      ` (${capturedImageShapes[index].shapes.length} labels)`}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </MuiDialog>

      <MuiDialog
        open={storageImagesDialogOpen}
        onClose={() => setStorageImagesDialogOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={2}>
            {storageImages.map((image, index) => {
              const savedShapes = storageImageShapes[index]?.shapes
              const labelsWithCategories =
                savedShapes ||
                image.labels?.map((label) => ({
                  ...label,
                  categories: [categoryMappings.index[label.categoryIndex] || 'Unknown'],
                }))

              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Box className={classes.dialogImageWrapper}>
                    <ImageWithLabels
                      image={image.url}
                      shapes={labelsWithCategories}
                      containerClass={classes.dialogImageContainer}
                      imageClass={classes.dialogImage}
                      onClick={() => {
                        handleStorageImageClick(image, index)
                        setStorageImagesDialogOpen(false)
                      }}
                    />
                    <Typography variant="caption">
                      Image {index + 1}
                      {labelsWithCategories?.length > 0 && ` (${labelsWithCategories.length} labels)`}
                    </Typography>
                  </Box>
                </Grid>
              )
            })}
            {hasMore && (
              <Grid item xs={12}>
                <Button onClick={handleLoadMore} disabled={isLoadingMore} fullWidth variant="outlined">
                  {isLoadingMore ? 'Loading...' : 'Load More'}
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </MuiDialog>

      {/* Categories Dialog */}
      {dialog.show && (
        <LabelDialog
          items={dialog.shape.categories}
          itemsChanged={selectedCategoriesChanged}
          onEdit={() => {
            annotator.edit(dialog.shape.id)
            hideAndUpdateCategories()
          }}
          onDelete={() => {
            annotator.delete(dialog.shape.id)
            hideDialog()
          }}
          onClose={hideAndUpdateCategories}
          offset={dialog.shape.getCenterWithOffset()}
        />
      )}
    </div>
  )
}

export default ImageLabeler
