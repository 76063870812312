import React, { useState, useEffect, useRef } from 'react'
import { ImageAnnotator } from 'react-image-label'

const CustomImageAnnotator = ({
  imageUrl,
  setHandles,
  shapes,
  onAdded,
  onContextMenu,
  onReady,
  onEditDone,
  ...props
}) => {
  const [canvas, setCanvas] = useState(null)
  const imgRef = useRef(null)

  useEffect(() => {
    const img = new Image()
    img.crossOrigin = 'anonymous'

    img.onload = () => {
      const newCanvas = document.createElement('canvas')
      newCanvas.width = img.width
      newCanvas.height = img.height

      const ctx = newCanvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      const dataUrl = newCanvas.toDataURL('image/png')
      imgRef.current = dataUrl
      setCanvas(dataUrl)
    }

    img.onerror = (err) => {
      console.error('Error loading image:', err)
    }

    img.src = imageUrl
  }, [imageUrl])

  const handleReady = (annotator) => {
    // Set zoom mặc định khi annotator ready
    if (annotator) {
      annotator.zoom(3.5)
    }
    // Gọi callback onReady gốc nếu được truyền vào
    if (onReady) {
      onReady(annotator)
    }
  }

  if (!canvas) {
    return <div>Loading...</div>
  }

  return (
    <ImageAnnotator
      {...props}
      imageUrl={canvas}
      setHandles={setHandles}
      shapes={shapes}
      onAdded={onAdded}
      onContextMenu={onContextMenu}
      onReady={handleReady}
      onEditDone={onEditDone}
    />
  )
}

// Add useImageAnnotator hook
export const useImageAnnotator = () => {
  const [annotator, setAnnotator] = useState(null)

  const setHandles = (handles) => {
    setAnnotator(handles)
  }

  return { annotator, setHandles }
}

export default CustomImageAnnotator
