/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react'
import imageCaptureStyles from '../../pages/image_capture/image_capture.styles'

// Constants cho category mappings
const categoryMappings = {
  index: {
    0: 'Good',
    1: 'Double',
    2: 'Spur',
    3: 'Major',
  },
  name: {
    good: 0,
    double: 1,
    spur: 2,
    major: 3,
  },
}

const ImageWithLabels = ({ image, shapes, containerClass, imageClass, onClick, isSelected }) => {
  const imageRef = useRef(null)
  const containerRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const classes = imageCaptureStyles()

  useEffect(() => {
    const container = containerRef.current
    if (!container) {
      return undefined
    }

    const updateDimensions = () => {
      setDimensions({
        width: container.clientWidth,
        height: container.clientHeight,
      })
    }

    updateDimensions()
    const observer = new ResizeObserver(updateDimensions)
    observer.observe(container)

    return function cleanup() {
      observer.disconnect()
    }
  }, [])

  const calculateImageScale = (imageElement, containerWidth, containerHeight) => {
    if (!imageElement || !imageElement.naturalWidth) {
      return { scaleX: 1, scaleY: 1, offsetX: 0, offsetY: 0 }
    }

    const { naturalWidth, naturalHeight } = imageElement
    const scaleX = containerWidth / naturalWidth
    const scaleY = containerHeight / naturalHeight
    const scale = Math.min(scaleX, scaleY)

    return {
      scaleX: scale,
      scaleY: scale,
      offsetX: (containerWidth - naturalWidth * scale) / 2,
      offsetY: (containerHeight - naturalHeight * scale) / 2,
    }
  }

  const renderShape = (shape, index) => {
    if (shape.type !== 'rectangle' || !shape.points) return null

    const [x_min, y_min] = shape.points[0]
    const [x_max, y_max] = shape.points[2]

    const { scaleX, scaleY, offsetX, offsetY } = calculateImageScale(
      imageRef.current,
      dimensions.width,
      dimensions.height
    )

    // Get correct category label
    let categoryLabel
    if (shape.categoryIndex !== undefined) {
      // If shape is from storage (has categoryIndex)
      categoryLabel = categoryMappings.index[shape.categoryIndex]
    } else if (shape.categories && shape.categories.length > 0) {
      // If shape is from annotator (has categories array)
      categoryLabel = shape.categories.join(', ')
    } else {
      categoryLabel = 'Unknown'
    }

    return (
      <div
        key={index}
        style={{
          position: 'absolute',
          left: `${x_min * scaleX + offsetX}px`,
          top: `${y_min * scaleY + offsetY}px`,
          width: `${(x_max - x_min) * scaleX}px`,
          height: `${(y_max - y_min) * scaleY}px`,
          border: '2px solid #2A3858',
          backgroundColor: 'rgba(42, 56, 88, 0.1)',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      >
        <div className={classes.labelText}>{categoryLabel}</div>
      </div>
    )
  }

  const handleImageLoad = () => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      })
    }
  }

  return (
    <div ref={containerRef} className={containerClass}>
      <img
        ref={imageRef}
        src={image}
        crossOrigin="anonymous"
        className={`${imageClass} ${isSelected ? classes.selectedImage : ''}`}
        onClick={onClick}
        onLoad={handleImageLoad}
        alt=""
      />
      {shapes?.map(renderShape)}
    </div>
  )
}

export default ImageWithLabels
