import { makeStyles } from '@material-ui/core/styles'

const imageCaptureStyles = makeStyles((theme) => ({
  // Main Container
  container: {
    display: 'flex',
    padding: '1.25rem',
    maxWidth: '100vw',
    boxSizing: 'border-box',
  },

  // Sidebar
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '15rem',
    minWidth: '15rem',
    marginRight: '1.25rem',
    gap: '0.625rem',
  },

  // Main Content Area
  imageArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },

  // Section Header
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    backgroundColor: '#2A3858',
    borderRadius: '0.5rem 0.5rem 0 0',
    marginBottom: 0,
    '& .MuiTypography-h6': {
      color: '#fff',
      fontSize: '1rem',
      fontWeight: 500,
    },
    '& .MuiIconButton-root': {
      color: '#fff',
      padding: '0.5rem',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
  },

  // Video Stream
  videoStreamBody: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '0.5rem',
    marginBottom: '0.625rem',
    width: '100%',
    border: '0.125rem solid black',
    aspectRatio: '16/9',
  },

  // Image Scroll Area
  imageScrollArea: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%',
    backgroundColor: '#f5f5f5',
    borderRadius: '0 0 0.5rem 0.5rem',
    padding: '1rem',
    marginBottom: '1.25rem',
    boxShadow: '0 0.125rem 0.25rem rgba(0,0,0,0.1)',
    '&::-webkit-scrollbar': {
      height: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '0.25rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2A3858',
      borderRadius: '0.25rem',
      '&:hover': {
        backgroundColor: '#1a2348',
      },
    },
  },

  // Thumbnail Container
  thumbnailContainer: {
    position: 'relative',
    width: '12.5rem',
    height: '9.375rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    flexShrink: 0,
    marginRight: '1rem',
    background: '#f5f5f5',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      backgroundColor: '#fff',
    },
  },

  // Captured Image
  capturedImage: {
    transition: 'all 0.3s ease',
    cursor: 'pointer',
  },

  // Selected Image
  selectedImage: {
    border: '0.1875rem solid #2A3858',
    borderRadius: '0.5rem',
    boxShadow: '0 0 0 0.25rem rgba(42, 56, 88, 0.2)',
  },

  // Labels Overlay
  labelsOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },

  // Label Text
  labelText: {
    position: 'absolute',
    top: '-1.25rem',
    left: 0,
    backgroundColor: '#2A3858',
    color: 'white',
    padding: '0.125rem 0.375rem',
    borderRadius: '0.25rem',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    zIndex: 2,
  },

  // Image Wrapper
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    margin: '0 0.5rem',
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'translateY(-0.1875rem)',
    },
  },

  // Storage Area
  storageArea: {
    width: '100%',
    marginBottom: '1.25rem',
    backgroundColor: '#f5f5f5',
    borderRadius: '0 0 0.5rem 0.5rem',
    display: 'flex',
  },

  // Storage Scroll Area
  storageScrollArea: {
    flex: 1,
    display: 'flex',
    overflowX: 'auto',
    padding: '1rem',
    gap: '1rem',
    '&::-webkit-scrollbar': {
      height: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '0.25rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2A3858',
      borderRadius: '0.25rem',
      '&:hover': {
        backgroundColor: '#1a2348',
      },
    },
  },

  // Load More Container & Button
  loadMoreContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px dashed #2A3858',
    padding: '0.5rem 1rem',
    minWidth: '7.5rem',
  },

  loadMoreButton: {
    whiteSpace: 'nowrap',
    color: '#2A3858',
    '&:hover': {
      backgroundColor: 'rgba(42, 56, 88, 0.04)',
    },
    '&.Mui-disabled': {
      color: 'rgba(42, 56, 88, 0.38)',
    },
  },

  loadMoreText: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.875rem',
    fontWeight: 500,
  },

  loadingSpinner: {
    color: '#2A3858',
  },

  // Dialog Styles
  dialogImageContainer: {
    position: 'relative',
    width: '100%',
    height: '18.75rem',
    borderRadius: '0.25rem',
    overflow: 'hidden',
    background: '#f5f5f5',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      backgroundColor: '#fff',
    },
  },

  dialogImageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5rem',
    border: '1px solid #eee',
    borderRadius: '0.5rem',
    transition: 'all 0.2s ease',
    height: 'fit-content',
    '&:hover': {
      transform: 'translateY(-0.1875rem)',
      boxShadow: '0 0.25rem 0.5rem rgba(0,0,0,0.1)',
    },
  },

  dialogImage: {
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },

  // No Images Message
  noImages: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: '1rem',
    '& .MuiTypography-body1': {
      marginBottom: '0.25rem',
    },
    '& .MuiTypography-caption': {
      color: theme.palette.text.secondary,
    },
  },

  // Category Dialog
  dialog_bg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },

  dialog: {
    backgroundColor: 'white',
    padding: '1.25rem',
    borderRadius: '0.5rem',
    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
    minWidth: '18.75rem',
  },

  // Loading State
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },

  // Annotator Container
  annotatorContainer: {
    margin: '1.25rem 0',
    height: 'calc(100vh - 15rem)',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    padding: '1.25rem',
    overflow: 'auto',
    boxSizing: 'border-box',
    position: 'relative',
    border: '1px solid #e0e0e0',
    boxShadow: '0 0.125rem 0.25rem rgba(0,0,0,0.1)',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
      height: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '0.25rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '0.25rem',
      '&:hover': {
        backgroundColor: '#555',
      },
    },
    '& > *': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    '& img': {
      objectFit: 'contain',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    '@media (max-width: 80rem)': {
      height: 'calc(100vh - 20rem)',
    },
  },

  // Shapes Info
  shapesInfo: {
    marginTop: '1.25rem',
    padding: '1rem',
    backgroundColor: '#f5f5f5',
    borderRadius: '0.5rem',
    '& pre': {
      margin: 0,
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
  },

  // Detection Results
  detectionResults: {
    marginTop: '1.25rem',
    padding: '1rem',
    backgroundColor: '#f5f5f5',
    borderRadius: '0.5rem',
    '& ul': {
      margin: 0,
      padding: '0 0 0 1.25rem',
    },
    '& li': {
      marginBottom: '0.5rem',
    },
  },

  // Image Caption
  imageCaption: {
    marginTop: '0.25rem',
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },

  // Progress Area
  progressArea: {
    marginTop: '1rem',
    padding: '1rem',
    backgroundColor: '#f5f5f5',
    borderRadius: '0.5rem',
  },

  // Video Error Message
  videoError: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '1rem',
    borderRadius: '0.5rem',
    textAlign: 'center',
    color: 'red',
    zIndex: 10,
  },
}))

export default imageCaptureStyles
